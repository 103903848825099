import Navbar from "../Components/Navbar";
import Slide from "../Components/Slide";
import Footer from "../Components/Footer";
import Slider from "../Components/Slider";
import { Link } from "react-router-dom";



const Home = () => {

    return (
        <div className="home">
            <div className="eagle-animation">
                <img src="eagle-emblem.png" alt="" />
            </div>
            <div className="heroBanner d-flex flex-column">
                <Navbar />
                <div className="image-container d-flex flex-column justify-content-center" style={{ backgroundImage: "url(oil-worker.jpg)" }}>
                    <div className="overlay"></div>
                    <Slide delay={500} direction="right">
                        <h1>Adding Value to Your Business</h1>
                    </Slide>
                    <Slide delay={1000} direction="right">
                        <h3>Empowering your growth with tailored solutions, innovative strategies, and unwavering support. Together, let's elevate your success.</h3>
                    </Slide>
                    {/* <button>Read More</button> */}
                </div>
            </div>
            <div className="about d-flex">
                <div>
                    <h5>about us</h5>
                    <h2>simplifying your <span>business</span></h2>
                    <p>Our goal is to empower businesses through simplification, building strong, trusted partnerships 
                        along the way. We offer value-added services in Project Management, Business Development, 
                        Market Intelligence, Recruitment, and Procurement Support, ensuring your success at every step.
                    </p>
                    <Link to="/about">
                        <button>Read More</button>
                    </Link>
                </div>
                <div className="d-none d-md-block">
                    <img src="oil-rig.jpg" alt="oil rig" />
                </div>
            </div>
            <div className="card-container d-flex">
                {/* <Slide distance={200} duration={2} style={{minWidth: "300px"}}> */}
                    <div>
                        <div className="overlay d-flex">
                            <h1>1</h1>
                            <h3>our promise</h3>
                        </div>
                        <p>
                            To provide personnel, management consultancy and
                            contracting services that exceed the expectations
                            of our local and international customers & to meet
                            the highest standards of quality and management activities.
                        </p>
                    </div>
                {/* </Slide>
                <Slide distance={200} delay={500} duration={1.5}> */}
                    <div>
                        <div className="overlay d-flex">
                            <h1>2</h1>
                            <h3>our target</h3>
                        </div>
                        <p>
                            We strive to create a safer, more secure
                            and sustainable world by bringing together
                            the best to deliver project and solutions
                            that help our client accomplish their most
                            critical missions and objectives.
                        </p>
                    </div>
                {/* </Slide>
                <Slide distance={200} delay={1000} duration={1}> */}
                    <div>
                        <div className="overlay d-flex">
                            <h1>3</h1>
                            <h3>our aim</h3>
                        </div>
                        <p>
                            To provide personnel, management consultancy and
                            contracting services that exceed the expectations
                            of our local and international customers.
                        </p>
                    </div>
                {/* </Slide> */}
            </div>
            <div className="services" id="services">
                <h2>our <span>services</span></h2>
                <h5>We offer comprehensive solutions designed to streamline your operations, enhance growth, and drive success across every facet of your business.
                </h5>
                <div className="services-container d-flex">
                    <div>
                        <img src="services1.jpeg" alt="1" />
                        <div className="services-text">
                            <h5>Oil & Gas</h5>
                            <p>We offer specialised oil and gas services, we deliver comprehensive solutions from exploration
                                to production. Our expertise ensures efficient operations and maximised value in the
                                energy sector.</p>
                        </div>
                    </div>
                    <div>
                        <img src="services2.jpeg" alt="1" />
                        <div className="services-text">
                            <h5>Engineering</h5>
                            <p>Providing cutting-edge engineering services, we design and implement innovative solutions that drive 
                                efficiency and excellence in every project. Our expertise covers all phases from concept to execution.</p>
                        </div>
                    </div>
                    <div>
                        <img src="services3.jpeg" alt="1" />
                        <div className="services-text">
                            <h5>Project Management</h5>
                            <p>We offer expert guidance to help clients clarify project objectives, establish partnerships with key 
                                stakeholders, craft winning bid strategies, and meet all qualification and tender evaluation criteria.</p>
                        </div>
                    </div>
                    <div>
                        <img src="services4.jpeg" alt="1" />
                        <div className="services-text">
                            <h5>Market Intelligence</h5>
                            <p>We provide critical support for strategic and operational decisions across the entire value chain, from engineering to 
                                market. Our services cover everything from project scope and logistics to tax, customs, and governance support.</p>
                        </div>
                    </div>
                    <div>
                        <img src="services5.jpeg" alt="1" />
                        <div className="services-text">
                            <h5>Procurement</h5>
                            <p>We follow international standards to ensure transparent, objective, and efficient decision-making, delivering quality 
                                results on time and within budget to clients across Oil & Gas, Power, and Petrochemical industries. </p>
                        </div>
                    </div>
                    <div>
                        <img src="services6.jpeg" alt="1" />
                        <div className="services-text">
                            <h5>Manpower & Recruitment</h5>
                            <p>Our advanced recruitment system and efficient team manage a growing global database of qualified candidates, enabling 
                                us to recruit and deploy top experts of any specialisation anywhere in the world.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="governance" id="governance">
                <h2>corporate <span>governance</span></h2>
                <h5>We are a value-added technical partner focused on Project Management, Business Development and
                    Market Intelligence Services, Manpower & Recruitment Services and Procurement Support & Services.</h5>
                <br /><br />
                <Slider />
            </div>

            <div className="contact d-flex" id="contact">
                <div>
                    <h2>get in <span>touch</span></h2>
                    <div className="d-flex flex-column flex-md-row gap-4 mt-4">
                        <div className="flex-fill">
                            <p> First Name <span className="text-danger">*</span></p>
                            <input type="text" id="firstName" />
                        </div>
                        <div className="flex-fill">
                            <p> Last Name <span className="text-danger">*</span></p>
                            <input type="text" id="lastName" />
                        </div>
                    </div>
                    <br />
                    <p>Email<span className="text-danger">*</span></p>
                    <input type="text" id="email" />
                    <br /><br />
                    <p>Message<span className="text-danger">*</span></p>
                    <textarea id="message" cols="30" rows="10"></textarea>
                    <p className="text-danger">* Required Field</p>
                    <br />
                    <br />
                    <button>Submit</button>
                </div>
                <div className="d-none d-md-flex align-items-center">
                    <img src="email.png" alt="" />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Home;