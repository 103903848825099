import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from 'framer-motion'

const Slide = ({ children, direction = "right", delay = 0, duration = 1, distance = 50 }) => {
    const controls = useAnimation();
    const ref = useRef(null)
    const inView = useInView(ref);

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    const getInitialPosition = () => {
        switch (direction) {
            case "up":
                return { y: distance };
            case "down":
                return { y: -distance };
            case "left":
                return { x: distance };
            case "right":
                return { x: -distance };
            default:
                return { y: distance };
        }
    };

    // Animation variants
    const variants = {
        hidden: { opacity: 0, ...getInitialPosition() },
        visible: {
            opacity: 1,
            x: 0,
            y: 0,
            transition: { duration: duration, ease: "easeInOut", delay: delay / 1000 }
        }
    };

    return (
        <motion.div
            style={{ position: "relative", zIndex: 2, overflow: "hidden" }}
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={variants}
        >
            {children}
        </motion.div>
    );
}

export default Slide;