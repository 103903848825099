import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Slider = () => {

    return (
        <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={true}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            // pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={true}
            responsive={{
                desktop: {
                    breakpoint: {
                        max: 3000,
                        min: 1300
                    },
                    items: 3,
                    slidesToSlide: 3,
                    partialVisibilityGutter: 40
                },
                mobile: {
                    breakpoint: {
                        max: 900,
                        min: 0
                    },
                    items: 1,
                    slidesToSlide: 1,
                    partialVisibilityGutter: 30
                },
                tablet: {
                    breakpoint: {
                        max: 1300,
                        min: 900
                    },
                    items: 2,
                    slidesToSlide: 2,
                    partialVisibilityGutter: 30
                }
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={true}
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            <div className="slider-card">
                <div>
                    <img src="sustainability.svg" alt="" />
                    <h5>Sustainability</h5>
                </div>
                <p>At Spurviva, we prioritise people and the planet. Our mission is to enhance diversity,
                    inclusion, and equality while guiding individuals to understand and optimise their energy consumption.</p>
            </div>
            <div className="slider-card">
                <div>
                    <img src="quality.svg" alt="" />
                    <h5>Quality</h5>
                </div>
                <p>We are dedicated to ensuring complete customer satisfaction. Every level of our organisation focuses
                    on meeting or surpassing customer expectations, consistently delivering high-quality work in line with project requirements.</p>
            </div>
            <div className="slider-card">
                <div>
                    <img src="leadership.svg" alt="" />
                    <h5>Leadership</h5>
                </div>
                <p>Our company prioritises leadership by fostering a culture of innovation, accountability, and growth, ensuring that
                    our leaders drive success and inspire excellence across all levels of the organisation.</p>
            </div>
            <div className="slider-card">
                <div>
                    <img src="diversity.svg" alt="" />
                    <h5>Diversity</h5>
                </div>
                <p>We embrace diversity by creating an inclusive environment where varied perspectives are valued, driving creativity
                    and innovation throughout our organisation.</p>
            </div>
            <div className="slider-card">
                <div>
                    <img src="codeofethics.svg" alt="" />
                    <h5>Code of Ethics</h5>
                </div>
                <p>Our code of ethics is central to our operations, ensuring integrity, transparency, and accountability in all our actions.
                    We are dedicated to maintaining these standards to build trust and uphold our core values.</p>
            </div>
            <div className="slider-card">
                <div>
                    <img src="professionalism.svg" alt="" />
                    <h5>Professionalism</h5>
                </div>
                <p>We uphold the highest standards of professionalism, ensuring that every interaction and service reflects our commitment to
                    excellence, reliability, and ethical conduct.</p>
            </div>
        </Carousel>
    );
}

export default Slider;