import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import Home from './Pages/Home';
import About from './Pages/About';
import Slider from './Components/Slider';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/slider" element={<Slider />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
