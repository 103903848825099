import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { IconButton, SwipeableDrawer } from '@mui/material'
import { MenuOutlined } from '@mui/icons-material'
import '../Stylesheets/Home.css'
import { useState } from 'react';

const Navbar = () => {
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path ? 'active' : 'inactive';
    };

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setOpen(open);
    };

    return (
        <div className="navbar" style={{ backgroundImage: "url(footers.jpeg)" }}>
            {/* <div className="overlay"></div> */}
            <img height={70} src="logofinal.png" alt="logo" />
            <IconButton onClick={toggleDrawer(true)} className='d-md-none'> <MenuOutlined /> </IconButton>
            <SwipeableDrawer
                anchor={'right'}
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <div className='d-flex gap-4 flex-column navbar'>
                    <Link to={"/"} className={isActive("/")}>Home</Link>
                    <Link to={'/about'} className={isActive("/about")}>About Us</Link>
                    <HashLink smooth to="/#services" className={isActive("/#services")}>Our Services</HashLink>
                    <HashLink smooth to="/#governance" className={isActive("/#governance")}>Corporate Governance</HashLink>
                    <HashLink smooth to="/#contact" className={isActive("/#contact")}>Contact Us</HashLink>
                </div>
            </SwipeableDrawer>
            <div className='gap-4 d-none d-md-flex'>
                <Link to={"/"} className={isActive("/")}>Home</Link>
                <Link to={'/about'} className={isActive("/about")}>About Us</Link>
                <HashLink smooth to="/#services" className={isActive("/#services")}>Our Services</HashLink>
                    <HashLink smooth to="/#governance" className={isActive("/#governance")}>Corporate Governance</HashLink>
                    <HashLink smooth to="/#contact" className={isActive("/#contact")}>Contact Us</HashLink>
            </div>
        </div>
    );
}

export default Navbar;