import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import '../Stylesheets/About.css'

const About = () => {
    return ( 
        <div id="about">
            <div>
            <Navbar/>
            <div className="heroBanner" style={{backgroundImage: "url(about.jpeg)"}}>
                <div className="overlay">about us</div>
            </div>
            </div>

            <div className="text-container">
                <h1>Who We Are</h1>
                {/* <div style={{ height: 80 , float: "right"}}></div> */}
                <div className="d-flex align-items-center justify-content-between">
                    <p>We are a value-added commercial partner specializing in Project Management, 
                        Business Development, Market Intelligence, Manpower & Recruitment, and 
                        Procurement Support. Our team brings years of experience in 
                        conventional and unconventional oil and gas, exploration and production, 
                        pipelines, chemicals, project management, stakeholder engagement, 
                        infrastructure, retail, and shipping. This extensive expertise has 
                        built a robust network of contacts with potential customers and 
                        suppliers, enabling us to meet client needs with tailored solutions. 
                        We leverage this experience to help clients find technical and strategic 
                        solutions and select the best materials for specific challenges. Our 
                        strength lies in our adaptability and openness to exploring innovative 
                        solutions and new market partnerships.
                        </p>
                        <img src="worker.jpeg" className="d-none d-lg-block" alt="" />

                </div>
            </div>

            <div className="text-container">
                <h1>What We Do</h1>
                <p>
                Spurviva is a global project management consulting firm that collaborates with leading 
                international contractors to capitalise on key business opportunities and enhance 
                trade value. Spurviva specialises in Business Development, Market 
                Intelligence, Manpower & Recruitment, and Procurement Support across core sectors 
                including oil and gas, petrochemicals, construction, infrastructure, power, and 
                marine. We effectively serve clients worldwide, bridging Europe,
                 Asia, the Middle East, and the Far East, and boast an impressive roster of clients 
                 ranging from niche industry players to multinational corporations.

                </p>
            </div>
            <Footer/>
        </div>
     );
}
 
export default About;